<template>
  <div>
    <span style="font-size: 12px">项目系统概述：</span>
    <div class="coalblock" v-for="item in options" :key="item.title">
      <span style="font-size: 12px; color: #9196a1">{{ item.title }}</span>
      <br />
      <span style="font-size: 24px">{{ item.num }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "coalOverView",
  mounted() {
    //获取session中的user的id和name
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    this.getRequest("/coalSystemManage/getSystemOverviewByUId?userId=" + this.currOperator).then((resp) => {
      if (resp.data) {
        this.options[0].num = resp.data.systemCount + "个";
        this.options[1].num = resp.data.subSystemCount + "个";
        this.options[2].num = resp.data.equipmentCount + "个";
      }
    });
  },
  data () {
    return {
      options: [
        {
          title: "系统个数",
          num: "8个",
        },
        {
          title: "子系统系统个数",
          num: "12个",
        },
        {
          title: "设备个数",
          num: "125",
        },
      ],
      currOperator: '',
      currOperatorName: ''
    }
  }
}
</script>

<style scoped>

</style>